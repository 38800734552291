.header {
  & > .container {
    display: flex;
    flex-direction: column;
  }

  background-color: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,0.15);

  &__actions {
    align-self: flex-end;
    & > * {
      margin-right: 7px;
      &:last-child { margin-right: 0}
    }
  }
  img {
    width: 125px;
    align-self: flex-start;
  }
}
@media screen and (max-width: 991px) {
  .navigation {
    .btn-primary {
      background-color: transparent;
      color: #000 !important;
      border: none;
      text-align: left;
      border-bottom: 1px solid #eee;
      border-radius: 0;
      padding-left: 0 !important;
    }
    .btn-primary.nav-link:focus {
      box-shadow: none;
      color: white !important;
    }
    .overlay {
      display: none;
      position: fixed;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.7);
      z-index: 1040;
      opacity: 1;
      top: 0;
      left: 0;
    }
    .overlay.active {
      display: block;
      opacity: 1;
    }
  }
  #responsive-navbar-nav {
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .navbar-nav {
    position: fixed;
    top: 0;
    right: 100%;
    padding-left: 0 !important;
    padding-right: 0px;
    padding-bottom: 15px;
    width: 85%;
    transition: all 0.2s ease;
    display: block !important;
    height: 100%;
    z-index: 9999;
    background: white;
    margin: 0;
    a.btn-primary {
      padding:12px 15px !important;
      color: black;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      i {
        font-size: 20px;
        margin-right: 3px;
      }
    }
  }
  .navbar-collapse.show .navbar-nav {
    right: 15%;
  }
  .nav-overlay {
    background-color: rgba(0, 0, 0, .5);
    transition: all 0.3s ease;
  }
}
@media screen and (max-width: 575px) {
  .header {
    height: 75px;
  }
}